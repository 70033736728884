import httpClient from './client';


const HOST = process.env.MIX_API_BACK;

// you can pass arguments to use as request parameters/data
const searchMixed = (value) => httpClient.get(`${HOST}/search/mixed/${value}`);
const searchHst = (value,cid) => httpClient.get(`${HOST}/search/hst/${value}`,{params: {
        rel: cid
    }});

const searchHstNear = (id,value = null,rel = null) => httpClient.get(`${HOST}/hst/${id}/nearhst`,{params: {
        rel: rel,
        value:value,
        upTo:20,
        radius:10
    }});


const searchCitiesNear = (id) => httpClient.get(`${HOST}/city/near/${id}`);

const hstById = (id) => httpClient.get(`${HOST}/hst/${id}`);


const detectParser = (lat,lng,cid) => httpClient.get(`${process.env["MIX_NVPARSER"]}/traffic/detect`,{
    params:{
        lat:lat,
        lng:lng,
        cid:cid
    }
});
const searchStreet = ({lat,lng,name}) => httpClient.get(`${process.env["MIX_NVPARSER"]}/street/byName`,{
    params:{
        lat:lat,
        lng:lng,
        name:name
    }
});


const calcNv = ({provider,origin,destination}) => httpClient.get(`${process.env["MIX_NVPARSER"]}/traffic/${provider}/calc`,{
    params:{
        origin:origin,
        destination:destination,
    }
});

export {
    searchCitiesNear,
    hstById,
    searchHstNear,
    calcNv,
    searchMixed,
    searchHst,
    detectParser,
    searchStreet
}
