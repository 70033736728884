import {EventBus, EVENTBUS_QUICKJOURNEY, EVENTBUS_REDIR, EVENTBUS_SHOW_DATE} from "@/js/eventbus";
import Sticky from "sticky-js/src/sticky";
import AOS from 'aos';


export default class Outside{




    loadEq(){

        let selectors = document.querySelectorAll('[data-eq="true"]');
        selectors.forEach((selector)=>{

            const viewCondition = selector.getAttribute('data-eq-view');
            if(viewCondition!=='undefined' && viewCondition!==null){
                if(window.innerWidth < parseInt(viewCondition)){
                    console.log('Skip EQ. Viewport does not match');
                    return true;
                }
            }
            let target = selector.getAttribute('data-eq-ref');
            if(target==='undefined' || target===null){
                console.log('Skip EQ. No Target');
                return true;
            }
            target = target.split(",");
            let allDirectCols = selector.querySelectorAll(":scope > [class*='col-']");
            const refHeight = allDirectCols[target[0]].clientHeight;
            allDirectCols[target[1]].style.height = refHeight+"px";
        });

    }


    constructor(v) {
        this.v = v;
        this.isBlocked = false;

        if(process.env.MIX_LOAD_EQ===true || process.env.MIX_LOAD_EQ==='true'){
            this.loadEq();
        }
        try {
            this.isBlocked = (window.mob===true)?false:!v.$store.state.common.block;
        }catch(e){
            this.isBlocked = false;
        }


        let element =  document.getElementById('buslinien_name');
        if (typeof(element) != 'undefined' && element != null) {
            this.loadCity().then(() => {
                console.log('Cities loaded');
            });
        }


        element =  document.getElementById('map');
        if (typeof(element) != 'undefined' && element != null)
        {
            this.loadLinie().then(()=>{


                let elements = document.getElementsByClassName('action');
                Array.from(elements).forEach((t) =>{
                    t.addEventListener('click', (e)=>{
                        e.preventDefault();
                        const id = t.getAttribute('data-id');
                        const action = t.getAttribute('data-trigger');
                        if(action===null){return true;}
                        console.log('emitting '+action);
                        EventBus.$emit(action, id);
                        return false;

                    });
                });





            });


        }

        AOS.init({
            once:true
        });
    }
    check(){

        let s = new Sticky('.stick');

        setTimeout(()=>{

            if(this.isBlocked) {
                let elems = document.getElementsByClassName('adsbygoogle');

                Array.from(elems).forEach((elem) => {

                    if (elem.clientWidth === 0 ) {
                        let f = null;

                        let h = (!isNaN(parseInt(elem.style.height)))?parseInt(elem.style.height):(!isNaN(parseInt(elem.style.maxHeight)))?parseInt(elem.style.maxHeight):null;
                        let w = (!isNaN(parseInt(elem.style.width)))?parseInt(elem.style.width):(!isNaN(parseInt(elem.style.maxWidth)))?parseInt(elem.style.maxWidth):null;

                        if(h!==null && w!==null){

                            const r = h/w;
                            if(r <= .5){
                                f = "sh.jpg";
                            }else if(r > .5 && r < 1){
                                f = "sq.png";
                            }else{
                                f = "sv.jpg";
                            }
                        }else{
                            f="sq.png";
                        }


                        elem.classList.remove('adsbygoogle');
                        elem.innerHTML = `<a href='https://amzn.to/3lkPcLp' target='_blank'><img src='/dist/img/${f}' alt='Image'></a>`;

                    }
                });
            }
            s.update();
        },2000);
    }


    async loadLinie(){
        await import(/* webpackChunkName: "linie" */ './Linie.js');
    }

    async loadCity(){
        await import(/* webpackCunkName: "city" */ './City');
    }


    initEvents(){

            let elements  = null;

            elements = document.getElementsByClassName('quickJourney');
            Array.from(elements).forEach((t) =>{
                t.addEventListener('click', (e)=>{
                e.preventDefault();

            const origin = t.getAttribute('data-origin');
            const destination = t.getAttribute('data-destination');
            const oT = t.getAttribute('data-origin-type');
            const dT = t.getAttribute('data-destination-type');
            const dTN = t.getAttribute('data-destination-name');
            const oTN = t.getAttribute('data-origin-name');

            let p = {
                action:{"origin":{type:oT,id:origin,identifer:origin,name:oTN},"destination":{type:dT,id:destination,identifer:destination,name:dTN}},
                event:e
            };
            window.location.hash="/toCities";
            setTimeout(()=>{
                EventBus.$emit(EVENTBUS_QUICKJOURNEY,p);
            },200);

            return false;
        })});

        elements = document.getElementsByClassName('setDate');
        Array.from(elements).forEach((element) =>{
            element.addEventListener('click', (e)=>{
                const p = {
                    target:element.getAttribute('data-field'),
                    allow:element.getAttribute('data-allow'),
                    event:e
                };
                EventBus.$emit(EVENTBUS_SHOW_DATE,p);
                e.preventDefault();
                return false;
            });
        });

        elements = document.querySelectorAll('.nav-link,.link-block-3 span a,.link-block-4 span a,.link-block-3 a,.raction,a.list-group-item');
        Array.from(elements).forEach((element) =>{

               const force = element.classList.contains('raction');


                element.addEventListener('click', (e) => {
                    if(force || Math.random()<=.35) {
                        const link = force?element.getAttribute('data-href'):element.getAttribute('href');
                        if (typeof link === 'undefined' || link.substr(0, 1) === "#") {
                            return true;
                        }
                        e.preventDefault();
                        EventBus.$emit(EVENTBUS_REDIR, {
                            url: link
                        });
                        return false;
                    }
                    return true;
                });

        });

        return this;
    }

}
