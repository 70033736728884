<template>
    <div class="fbb-modal-content">
        <div class="modal-body p-md-0">
            <div class="row">
                <div class="col-12 text-center">
                    <h3>{{ loadingText }}</h3>
                </div>
                <div class="col-12 text-center"  rel="content_target" id="content_target">
                    <div ref="dtc" :id="cid" class="restDtc" data-load="SIZE_HOR_BIG"></div>
                </div>
                <div class="col-12 mt-2 text-center">

                    <div v-if="isLoading">
                        <button type="button" disabled class="btn btn-primary d-none">Bitte warten ...</button>
                    </div>
                    <div v-if="!isLoading && !pop && (url!==false)">
                        <a :href="getUrl()" :target="target" class="btn btn-primary">Weiter</a>
                    </div>
                    <div v-if="!isLoading && pop && (url!==false)">
                        <a :href="getUrl()" @click="popTest"  target="_blank" class="btn btn-primary">Weiter</a>
                    </div>
                  <div v-if="!isLoading && (url===false)">
                    <a  @click="$emit('close');"  target="_blank" class="btn btn-primary">Weiter</a>
                  </div>

                </div>
            </div>
        </div>
    </div>
</template>




<script type="text/babel">

    import {mapGetters} from "vuex";
    import {GET_RDR} from "@/js/state/modules/common.types";
    import Dtc from "@/js/components/mixins/dtc";

    export default {
      mixins:[Dtc],
        props: {
            'pop':{
                type:Boolean,
                required:false,
                default:false
            },
            'timeout': {
                type: Number,
                required: false,
                default: 5
            },

            'target':{
                type:String,
                required: false,
                default:'_self'
            },
            'url':{

                required: false,
                default:'#'
            },
            'fetch':{
                required:false,
                default:false
            },
          'params':{
            required:false,
            default:null
          }

        },
        computed:{
          ...mapGetters("common",{
            rdr:GET_RDR
          }),
            loadingText(){
                return (this.isLoading)?'Ihre Anfrage wird vorbereitet':'Fertig! Weiter...';
            }
        },
        name:'RedirModal',


        mounted(){
            this.notify();

            this.start();
            this.timer = window.setTimeout(()=>{
                this.isLoading = false;
                this.finish();
            },this.timeout*1000);

        },


        methods:{

            popTest(){

              const type = (this.rdr)?'amz':'bck';

                setTimeout(()=>{
                    const d = this.$store.state.dtc.origin;
                   let id = "0";
                   if(d.hasOwnProperty('id')){
                       id = d.id.toString();
                   }else if(d.hasOwnProperty('identifer')){
                       id = d.identifer.toString();
                   }else{
                       id = "0";
                   }
                    window.location.href= "/redir?type="+type+"&id="+id+"&name="+encodeURI(d.name);
                },500);

                return false;
            },

            getUrl(){
              return (this.gurl===null)?this.url:this.gurl;
            },
            finish(){
               console.log('finish');
            },

            start(){
                if(this.fetch!==false){

                 this.fetch(this.params).then((response)=>{
                   if(response.hasOwnProperty('url')) {
                     this.gurl = response.url;
                   }else if(response.hasOwnProperty('data')){
                     this.gurl = response.data;
                   }
                 });
                }
            }

        },
        created(){
          // eslint-disable-next-line no-unused-vars
            this.cid = [...Array(5)].map(i=>(~~(Math.random()*36)).toString(36)).join('');
        },
        data(){
            return {
                cid:null,
                timer:null,
                gurl:null,
                isLoading:true
            };
        }
        }
</script>
