<template>
  <div>

    <navbar></navbar>

    <div class="topContainer">
      <form class="searchWidget">
        <div class="search-input search-input-train">
              <modal-input label="In welcher Stadt?" :valid="check('origin')" :value="getHstOrigin" icon="#varioHome"  @click.native="showModalOrigin" />

              <modal-input label="Name der Haltestelle" :disabled="isDisabled()" :valid="check('destination')"  :value="getHst" icon="#varioPin"  @click.native="showModalHst" />
          <div class="action row">
            <div class="col-md-12 lg-pt-5">
              <button class="btn btn-cta finish" id="hst_finish"  type="button"
                      @click="finish()">Jetzt suchen
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<style type="text/css" scoped>
  #hst_finish{
    background-color:#de6f20;color:white;padding: 10px 23px 10px;
  }
</style>

<script type="text/babel">



import Select from "@/js/components/modals/select";

import ModalInput from "@/js/components/elements/modalInput";
import {mapActions, mapGetters} from "vuex";
import {getModalHeadline} from "@/js/Text";
import {RETRIEVE_SUGGESTIONS_HST, RETRIEVE_SUGGESTIONS_MIXED} from "@/js/state/modules/common.types";
import Navbar from "@/js/components/elements/navbar";
import {mixin as clickaway} from "vue-clickaway";
import {
  GET_ENTIRE_STATE,
  GET_HST,
  GET_HST_CITY,
  GET_HST_CITY_OBJECT, HST_GET_URL,
  HST_IS_VALID,
  SET_HST,
  SET_HST_CITY
} from "@/js/state/modules/hst.types";
import Redir from "@/js/components/modals/redir";
import {getHstLink} from "@/js/api/local";

const NAMESPACE = 'hst';

export default {
  name: 'hst',
  components: {Navbar, ModalInput},
  mixins: [clickaway],
  data(){
    return {
      submitted:false
    }
  },
  computed: {



    ...mapGetters(NAMESPACE, {
        isValid:HST_IS_VALID,
        getHstOrigin:GET_HST_CITY,
        getHstOriginObject:GET_HST_CITY_OBJECT,
        getHst:GET_HST,
        all:GET_ENTIRE_STATE
      }
    ),

  },
  methods:{
    ...mapActions(NAMESPACE,{
      setHst:SET_HST,
      setHstCity:SET_HST_CITY
    }),
    isDisabled(){

      return (this.getHstOriginObject === null);
    },
    check(which){
      if(!this.submitted){
        return true;
      }
      return this.isValid[which];
    },
    showModalOrigin(){
        this.showModal(
            getModalHeadline(NAMESPACE,GET_HST_CITY),
            {
                action:["common",RETRIEVE_SUGGESTIONS_MIXED],
                placeholder:'z.B. Berlin, Dortmund oder Hamburg Hbf',
                dispatch:[NAMESPACE,SET_HST_CITY]
            },
            true
        );
    },
    showModalHst(){
      if(this.isDisabled()){
        return;
      }
      this.showModal(
          getModalHeadline(NAMESPACE,GET_HST),
          {
            action:["common",RETRIEVE_SUGGESTIONS_HST],
            placeholder:'z.B. Berlin, Dortmund oder Hamburg Hbf',
            dispatch:[NAMESPACE,SET_HST]
          },
          true
      );
    },
    showModal(headline,instantConfig,showNear){


      const props = (window.mob !== true)?{
        maxWidth:1200,
        width:1200,
        height:'auto'
      }:{
        minWidth:window.innerWidth,
        width:window.innerWidth,
        minHeight:window.innerHeight,
        height:window.innerHeight,
      };


      this.$modal.show(Select, {
        headline:headline,
        instantConfig:instantConfig,
        showNear
      }, props);
    },






    finish(){
      this.submitted = true;
      if(!Object.values(this.isValid).every(item => item === true)){
          console.log("Is not valid!");
          return;
      }

      //this.$ga.event('topForm', 'info', 'valid');
      //let url = '/rest/hst/link?hst='+this.form.hst.id+'&city='+this.form.origin.id;

      this.$modal.show(Redir, {
        fetch:getHstLink,
        params:this.all,
        timeout:8
      },{
        maxWidth:800,
        width:800,
        height:300
      });



    }
  }
}
</script>
