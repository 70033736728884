<style scoped>
.searchIcon{
  top:4px;
}
</style>
<template>
  <div>

    <div class="form-group has-feedback">
      <input class="form-control form-control-sm "  @input="debounceInput" autocomplete="false"  v-on="listeners" type="search" id="example-search-input">
      <span class="form-control-feedback glyphicons-search glyphicons searchIcon">

            </span>
    </div>


    <div v-if="isLoading">
      <div class="loader"><img alt="spinner" src="/desktop/img/spinner.gif"></div>
    </div>




    <div class='el-input-group__append'>

      <ul  class="ais-results" style="max-height:500px;overflow-y:auto;">

        <slot  v-for="(result,index) in getSuggestions" :result="result" >
          <div :key="'slotSuggest'+index" class="search-item" @click="setSuggestion(index)">
            <div class="search-item-icon" :class="result.type"></div>
            <div class="search-item-text">
              <h3>{{result.name }}</h3>
            </div>
          </div>
        </slot>
      </ul>
    </div>




  </div>
</template>
<script type="text/babel">



import debounce from "debounce";
import {mapActions, mapGetters} from "vuex";
import {
  RETRIEVE_SUGGESTIONS_MIXED,
  GET_SUGGESTIONS,
  IS_LOADING,
  CLEAR_SUGGESTIONS
} from "@/js/state/modules/common.types";

export default {
  name:'instant',

  props:{
    config:{
      type:Object
    }
  },


  computed:{
    ...mapGetters('common',{
      getSuggestions:GET_SUGGESTIONS,
      isLoading:IS_LOADING
    }),
    listeners() {
      return {
        ...this.$listeners,
        // eslint-disable-next-line no-unused-vars
        input: e => {
          return null;
        },
      }
    },
  },

  data(){
    return {
      internalValue:null,
    };
  },

  methods:{
    ...mapActions("common",{
        retrieveSuggestionsMixed:RETRIEVE_SUGGESTIONS_MIXED,
        clearSuggestions:CLEAR_SUGGESTIONS
    }),

    setSuggestion(i){

      const item = this.getSuggestions[i];


      this.$store.dispatch(`${this.config.dispatch[0]}/${this.config.dispatch[1]}`,item);

      this.$emit('close');
      this.clearSuggestions();
    },

    handleKeyStroke(e) {
      const {keyCode} = e;
      const ignoredKeyCodes = [
        16, // Shift
        9,  // Tab
        17, // ctrl
        18, // alt/option
        91, // OS Key
        93  // Right OS Key
      ];
      return ignoredKeyCodes.indexOf(keyCode) <= -1;
    },

    debounceInput: debounce(function(e){



      if(e.target.value.length < this.minChars){
        this.isSoftError = true;
        this.softError = `Mindestens ${this.minChars} Zeichen eingeben`;
        return false;
      }
      this.$store.dispatch(`${this.config.action[0]}/${this.config.action[1]}`,e.target.value);


    },500),


    inputHandler(e) {
      const newValue = e.target.value
      this.$emit('input', newValue);
      this.internalValue = newValue;
    }
  }



}

</script>
