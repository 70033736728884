<style type="text/css" scoped>
#home_finish{
  background-color:#de6f20;color:white;padding: 10px 23px 10px;
}
</style>
<template>
  <div>

    <navbar></navbar>

    <div class="topContainer">
        <form class="searchWidget">
          <div class="search-input search-input-train">
            <modal-input label="In welcher Stadt geht's los?" :valid="check('origin')" :value="getOrigin" icon="glyphicons-home"  @click.native="showModalOrigin" />
            <modal-input label="Wohin möchten Sie?" :valid="check('destination')"  :value="getDestination" icon="glyphicons-flag"  @click.native="showModalDestination" />
            <date-input ref="dinput"></date-input>

            <div class="action row">
              <div class="col-md-8 lg-pt-5">

                <toggle-button v-model="pop"
                               :labels="false"
                               :sync="true"
                               color="#219c15"/>
                <span class="small text-dark lg-pl-10"
                      style="letter-spacing:.4px;color:white;">Partnerangebote erhalten</span>
              </div>
              <div class="col-md-4">
                <button class="btn btn-block ripple btn--primary" @click="finish()" id="topFormSubmit">
                  <span class="btn__text">Suchen</span>
                </button>
              </div>
            </div>
          </div>
      </form>
    </div>

  </div>
</template>

<style lang="less" scoped>
@import '../../../less/bootstrap/variables';
@import '../../../less/theme-variables';
@import '../../../less/custom/topForm';
</style>
<script type="text/babel">



import DateInput from "@/js/components/elements/dateInput";
import Select from "@/js/components/modals/select";
import Redir from "@/js/components/modals/redir";
import Passenger from "@/js/components/elements/passenger";
import ModalInput from "@/js/components/elements/modalInput";
import {mapActions, mapGetters} from "vuex";
import {
  GET_DATE,
  GET_DESTINATION, GET_LINK,
  GET_ORIGIN,
  IS_VALID,
  SET_DESTINATION,
  SET_ORIGIN
} from "@/js/state/modules/dtc.types";
import {getModalHeadline} from "@/js/Text";
import {GET_RDR, RETRIEVE_SUGGESTIONS_MIXED} from "@/js/state/modules/common.types";
import Navbar from "@/js/components/elements/navbar";
import {mixin as clickaway} from "vue-clickaway";
import {ToggleButton} from "vue-js-toggle-button";
import {EventBus, EVENTBUS_QUICKJOURNEY} from "@/js/eventbus";

const NAMESPACE = 'dtc';

export default {
  name: 'home',
  components: {Navbar, ModalInput,DateInput, ToggleButton: ToggleButton,},
  mixins: [clickaway],
  data(){
    return {
      submitted:false,
      pop:true,
      quickJourney:false,
    }
  },
  computed: {

    ...mapGetters("common",{
      rdr:GET_RDR
    }),
    ...mapGetters(NAMESPACE, {
        isValid:IS_VALID,
        getDate:GET_DATE,
        getOrigin:GET_ORIGIN,
        getDestination:GET_DESTINATION,
        getLink:GET_LINK
      },
    ),

  },


  mounted() {
    EventBus.$on(EVENTBUS_QUICKJOURNEY, (data) => {

      let action = data.action;
      if (action.hasOwnProperty('origin')) {
        this.setOrigin(action.origin);
      }
      if (action.hasOwnProperty('destination')) {
        this.setDestination(action.destination);
      }

      this.quickJourney = true;
      this.$refs.dinput.$refs.datetime.open(data.event);


    });
  },
  watch:{
    getDate(){
      if(this.quickJourney) {
        this.quickJourney = false;
        this.finish();
      }
    }
  },
  methods:{
    ...mapActions("dtc",{
      setOrigin:SET_ORIGIN,
      setDestination:SET_DESTINATION
    }),

    check(which){
      if(!this.submitted){
        return true;
      }
      return this.isValid[which];
    },
    showModalOrigin(){
        this.showModal(
            getModalHeadline(NAMESPACE,GET_ORIGIN),
            {
                action:["common",RETRIEVE_SUGGESTIONS_MIXED],
                placeholder:'z.B. Berlin, Dortmuznd oder Hamburg Hbf',
                dispatch:[NAMESPACE,SET_ORIGIN]
            },
            true
        );
    },
    showModalDestination(){
      this.showModal(
          getModalHeadline(NAMESPACE,GET_DESTINATION),
          {
            action:["common",RETRIEVE_SUGGESTIONS_MIXED],
            placeholder:'z.B. Berlin, Dortmuznd oder Hamburg Hbf',
            dispatch:[NAMESPACE,SET_DESTINATION]
          },
          true
      );
    },
    showModal(headline,instantConfig,showNear){

      const props = (window.mob !== true)?{
        maxWidth:1200,
        width:1200,
        height:'auto'
      }:{
        maxWidth:300,
        width:window.innerWidth,
        height:'100%'
      };


      this.$modal.show(Select, {
        headline:headline,
        instantConfig:instantConfig,
        showNear
      }, props);
    },

    finish(){
      this.submitted = true;
      if(!Object.values(this.isValid).every(item => item === true)){
        console.log("Is not valid!");
        return;
      }




      this.$modal.show(Redir, {
        url: this.getLink,
        timeout: 5,
        pop: this.pop
      }, {
        maxWidth: 800,
        width: 800,
        height: 300
      });


    }
  }
}
</script>
