import Home from '@/js/components/pages/home.vue';
import Hst from '@/js/components/pages/hst.vue';
const _routes = [
    {
        path: '/toCities',
        name: 'toCities',
        components: {
            default: Home
        }
    },

    {
        path: '*',
        name: 'hst',
        components: {
            default: Hst
        }
    }


];
export const routes = [
    ..._routes,
  {path:'notSet'}
];
