export const SET_STREET_ORIGIN = 'SET_STREET_ORIGIN';
export const GET_STREET_ORIGIN = 'GET_STREET_ORIGIN';

export const SET_STREET_DESTINATION = 'SET_STREET_DESTINATION';
export const GET_STREET_DESTINATION = 'GET_STREET_DESTINATION';

export const SET_STREET_CITY = 'SET_STREET_CITY';
export const GET_STREET_CITY = 'GET_STREET_CITY';
export const GET_STREET_CITY_OBJECT = 'GET_STREET_CITY_OBJECT';

export const BUS_IS_VALID = 'BUS_IS_VALID';
export const SET_STREET_PROVIDER = 'SET_STREET_PROVIDER';


export const RETRIEVE_NV_RESULTS = 'RETRIEVE_NV_RESULTS';
export const GET_NV_RESULTS = 'GET_NV_RESULTS';
export const SET_NV_RESULTS = 'SET_NV_RESULTS';