<template>
  <div class="inputWrapper">
    <label for="date">Wann geht's los?</label>

    <div v-on:click="showDate($event)" id="date" class="timeTrigger"  data-what="date" data-trigger="date" v-html="getDateStr"></div>

    <div class="icon">
      <i class="glyphicons glyphicons-calendar"></i>
    </div>


    <datetime

        ref="datetime"
      @close="setDate"
        type="datetime"
        value-zone="Europe/Berlin"
        zone="Europe/Berlin"
        v-model="currentDate"
        :date="currentDate"
        :format="{ year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: '2-digit', timeZoneName: 'short' }"
        :hour-step="1"
        :max-datetime="maxDate"
        :min-datetime="minDate"
        :minute-step="10"
        :phrases="{ok: 'Weiter', cancel: 'Zurück'}"
        :week-start="7"
        auto
        input-class="d-none hidden"

    ></datetime>



  </div>
</template>
<script type="text/babel">
import {mapActions, mapGetters} from "vuex";
import {GET_DATE, SET_DATE} from "@/js/state/modules/dtc.types";
import {DateTime as LuxonDateTime} from "luxon";
import {Datetime} from "vue-datetime";

const NAMESPACE = 'dtc';
export default {
  name:'date',
  components: {
    datetime: Datetime,
  },
  data(){
    return {
      currentDate: null,
      minDate: LuxonDateTime.local().toISO(),
      maxDate: LuxonDateTime.local().plus({days: 180}).toISO(),
    }
  },
  props:{
    icon:{
      type:String,
      default:'#varioCalendar'
    },
  },
  computed:{
    ...mapGetters(NAMESPACE,{
        getDate:GET_DATE
    }),
    getDateStr:function dstr(){
      let s = this.getDate;

      if (typeof s === 'string') {
        return s;
      } else {
        return s.setZone('europe/berlin').toFormat('dd.LL.yyyy HH:mm:00');
      }
    },
  },
  methods:{
    ...mapActions(NAMESPACE,{
      setVuexDate:SET_DATE
    }),
    setDate(){
      this.setVuexDate(this.$refs.datetime.$data.datetime);
    },

    showDate(e) {
        this.$refs.datetime.open(e);
    },

  }
}


</script>
