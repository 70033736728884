import {getDtcCode} from "@/js/api/local";
import {mapGetters} from "vuex";
import {IS_BLOCKED} from "@/js/state/modules/common.types";

export default {

    computed:{
      ...mapGetters("common",{
          block:IS_BLOCKED
      })
    },
    methods: {


        loadBlock(target,size){


            const f = (size.includes('_HOR_'))?'sh.jpg':'sq.gif';
            target.innerHTML = `<a href='https://amzn.to/3lkPcLp' target='_blank' style="margin: 0 auto"><img src='/dist/img/${f}' alt='Image'></a>`;
        },

        async notify(id = null) {
            return new Promise((resolve)=>{
                let target = (id === null)?this.$refs.dtc:document.getElementById(id);
                const size = target.getAttribute('data-load');

                if(this.block){
                    this.loadBlock(target,size);
                    return;
                }

                getDtcCode(size,window.md).then((data)=>{
                    target.innerHTML = data;
                    this.init();
                    resolve(true);
                });
            });
        },

        init(){

                try {
                    (adsbygoogle = window.adsbygoogle || []).push({});
                }catch(e){
                    console.error(e);
                    return false;
                }


        }
    }
}
