
import {getPlaceholder} from "@/js/Text";
import {
    BUS_IS_VALID, GET_NV_RESULTS,
    GET_STREET_CITY, GET_STREET_CITY_OBJECT,
    GET_STREET_DESTINATION,
    GET_STREET_ORIGIN, RETRIEVE_NV_RESULTS, SET_NV_RESULTS,
    SET_STREET_CITY, SET_STREET_DESTINATION, SET_STREET_ORIGIN, SET_STREET_PROVIDER
} from "@/js/state/modules/bus.types";
import {calcNv, detectParser} from "@/js/api/remote";
import { SET_IS_LOADING} from "@/js/state/modules/common.types";


const NAMESPACE = 'bus';




const state = {
    city:null,
    streetOrigin:null,
    streetDestination:null,
    streetProvider:null,
    nvResults:[]
};

const getters = {

    [GET_NV_RESULTS](state){
        return state.nvResults;
    },
    [BUS_IS_VALID](state){
        let temp = {
            city:true,
            streetOrigin:true,
            streetDestination:true,
        };
        if(typeof state.city !== 'object' || state.city===null || !state.city.hasOwnProperty('name')){
            temp.origin =false;
        }
        if(typeof state.streetOrigin !== 'object' ||state.streetOrigin===null ||  !state.streetOrigin.hasOwnProperty('name')){
            temp.streetOrigin = false;
        }
        if(typeof state.streetDestination !== 'object' ||state.streetDestination===null ||  !state.streetDestination.hasOwnProperty('name')){
            temp.streetDestination = false;
        }
        return temp;
    },
    [GET_STREET_ORIGIN]( state ) {
        return (state.streetOrigin === null)?getPlaceholder(NAMESPACE,GET_STREET_ORIGIN):state.streetOrigin;
    },
    [GET_STREET_DESTINATION]( state ) {
        return (state.streetDestination === null)?getPlaceholder(NAMESPACE,GET_STREET_DESTINATION):state.streetDestination;
    },
    [GET_STREET_CITY]( state ) {
        return (state.city === null)?getPlaceholder(NAMESPACE,GET_STREET_CITY):state.city;
    },
    [GET_STREET_CITY_OBJECT]( state ) {
        return state.city;
    },

}


const actions = {
    [SET_STREET_CITY]: ({commit,state},payload) => {
        commit(SET_STREET_CITY,payload);

        detectParser(state.city.location.lat,state.city.location.lng,state.city.id).then((res)=>{
           commit(SET_STREET_PROVIDER,res.data.provider);
        });

    },
    [SET_STREET_ORIGIN]: ({commit},payload) => {
        commit(SET_STREET_ORIGIN,payload);
    },
    [SET_STREET_DESTINATION]: ({commit},payload) => {
        commit(SET_STREET_DESTINATION,payload);
    },
    [SET_STREET_PROVIDER]: ({commit},payload) => {
        commit(SET_STREET_PROVIDER,payload);
    },
    [SET_NV_RESULTS]: ({commit},payload) => {
        commit(SET_NV_RESULTS,payload);
    },
    async [RETRIEVE_NV_RESULTS]({commit,state,dispatch}){
        dispatch(["common",SET_IS_LOADING].join("/"),true,{root:true});


        try {
            let data = await calcNv(
                {
                    provider:state.streetProvider,
                    origin:encodeURI([
                        state.streetOrigin.name,
                        state.streetOrigin.geo.lat,
                        state.streetOrigin.geo.lng
                    ].join(":")),
                    destination:encodeURI([
                        state.streetDestination.name,
                        state.streetDestination.geo.lat,
                        state.streetDestination.geo.lng
                    ].join(":")),
                }
            );
            commit(SET_NV_RESULTS,data.data);
        }catch(e){
            console.error(e);
        }finally {
            dispatch(["common",SET_IS_LOADING].join("/"),false,{root:true});
        }
    }
}

const mutations = {

    [SET_STREET_PROVIDER](state,provider){
        state.streetProvider = provider;
    },

    [SET_NV_RESULTS](state,results){
        state.nvResults = results;
    },

    [SET_STREET_CITY](state, city) {
        state.city = city;
    },

    [SET_STREET_DESTINATION](state, destination) {
        state.streetDestination = destination;
    },

    [SET_STREET_ORIGIN](state, origin) {
        state.streetOrigin = origin;
    },

}

export default {
    namespaced: true,
    getters,
    state,
    actions,
    mutations
}