<template>

  <div class="inputWrapper" id="fromWhereTrigger"  :class="getClasses">
    <label for="fromWhere">{{  label }}</label>
    <div id="fromWhere" class="cityComplete"  >
      {{ getValue}}</div>
    <div class="icon">
      <i class="glyphicons" :class="icon"></i>
    </div>
  </div>
</template>

<script type="text/babel">
  export default {
    computed:{
      getValue(){

        return (typeof this.value === 'string')?this.value:this.value.name;
      },
      getClasses(){
        let c = [];
        c.push(!(this.valid)?'is-invalid':'valid');
        if(this.disabled){c.push('disabled');}
        return c;
      }
    },
    props:{
      label:{
        type:String,
        default:'test'
      },
      disabled:{
        type:Boolean,
        default:false
      },
      icon:{
        type:String,
        default:'#varioHome'
      },
      value:{
        default:null
      },
      valid:{
        type:Boolean,
        default:true
      }
    }
  }
</script>