<style>
.select .modal-body{
  padding:0!important;

}

.fbb-modal-content .row > [class*='col-'] {
  display: flex;
  flex-direction: column;

}
.fbb-modal-content  .row-flex {
  display: -webkit-box!important;
  display: -webkit-flex!important;
  display: -ms-flexbox!important;
  display:         flex!important;
  flex-wrap: wrap!important;
}

.topFormModalBg {
  background: url(/dist/img/bus.png) bottom left;
  background-size: auto 150px;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 600px) {
  #modals-container  .vm--container .vm--modal{
    background:#fff url(/dist/img/bus.png) bottom center;
    background-size: auto 150px;
    background-repeat: repeat-x;
  }
}

</style>

<template>
  <div class="fbb-modal-content select">

    <div class="modal-body p-md-0 container">
      <div class="row-flex no-gutters">
        <div v-if="!isMobile" class="col-md-4  col-xl-5 d-none  bg-img rounded-left topFormModalBg lg-pt-10" data-overlay-secondary
             data-overlay-light="6">
          <div ref="dtc" :id="rand" class="restDtc" data-load="SIZE_RECT_BIG"></div>
        </div>

        <div class="col-md-8 col-xs-12 col-xl-6 mx-auto lg-p-10 xs-p-0" style="min-height:400px!important;">

          <div v-if="isMobile" class="heading">
            <div @click="$emit('close')"><i class="glyphicons glyphicons-chevron-left"></i></div>
            <div class="heading_text">{{ headline }}</div>
          </div>
          <h5 v-if="!isMobile" class="fw-200 pt-3">{{ headline }}</h5>
          <div class="xs-p-10">
          <Instant @close="$emit('close')" :config="instantConfig"></Instant>
          </div>
          <div v-if="isMobile" >
            <div ref="dtc" :id="rand" class="restDtc" style="text-align: center" data-load="SIZE_RECT_BIG"></div>
          </div>




        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Instant from "@/js/components/elements/instant";
import Dtc from "@/js/components/mixins/dtc";
export default {
  components: {Instant},
  mixins:[Dtc],
  props: {

    headline: {
      type:String,
      default:'not set'
    },
    instantConfig:{
      type:Object,
    },
    showNearLocations:{
      type:Boolean,
      default:false
    }

  },
  name: 'CityModal',
  data() {
    return {
      cid: null,


      usernear: [],
      value: "",

    }
  },
  watch: {
    value(i) {
      this.noData = !(i !== null && i.length >= 1);
      return i;
    }
  },
  mounted() {
    let i = document.getElementsByClassName('vm--modal')[0];
    let k = document.getElementsByClassName('vm--container')[0];
    let before = i.style.height;
    this.notify().then(()=>{
      setTimeout(()=> {
        i.style.height = before;
        k.style.height = before;
      },100);
    });
  },
  computed: {
    isMobile(){
      return (window.mob===true);
    },
    rand() {
      return this.cid;
    },

  },
  beforeDestroy() {
    document.getElementById('body').classList.remove('blocked');
  },
  beforeMount() {
    document.getElementById('body').classList.add('blocked');
    if (null === this.cid) {
      // eslint-disable-next-line no-unused-vars
      this.cid = [...Array(5)].map(i => (~~(Math.random() * 36)).toString(36)).join('');
    }
  }
}
</script>
